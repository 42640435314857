// Formulario.js
import React, { useState } from 'react';
import LogosUas from '../assets/logos_uas.png';
import LogoFeria from '../assets/logo_feria_libro.png';
import axios from 'axios';
import { Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import { Helmet } from 'react-helmet';

const CertificateFeliuasFormPage = () => {
  const [validated, setValidated] = useState(false);
  const [certificate, setCertificate] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    name: "",
  });
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
        return;
    } else {
      setValidated(true);
    }

    setLoading(true);

    axios.post('https://api.constancias-urs.uas.edu.mx/certificate', {
    //axios.post('http://localhost:4000/certificate', {
      email:  formData.email,
      name: formData.name,
      id_event: 1,
    })
    .then(response => {
      if(response && response.data && response.data.success) {
        const url = 'https://constancias-urs.uas.edu.mx/certificates/1/' + response.data.filename;
        setCertificate(url);
      } else {

        if(response && response.data && response.data.error_description) {
          toast(response.data.error_description, {
            type: 'warning'
          });
        } else {
          toast("No se encontro ningun registro.", {
            type: 'warning'
          });
        }
      }
      setLoading(false);
    })
    .catch( error => {
      toast("Ocurrio un error, intenta de nuevo.", {
        type: 'error'
      });
      setLoading(false);
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(/^(\w{3,}\s+){1,}\w{3,}$/.test(formData.name))
    setFormData({
        ...formData,
        [name]: value,
    });
  };

  const handleDownload = () => {
    const pdf = new jsPDF({
      orientation: 'landscape',
      format: 'letter'
    });
    const imgWidth = 280; // Ancho del documento PDF
    const imgHeight = 216; // Calcular la altura proporcional

    pdf.addImage(certificate, 'JPEG', 0, 0, imgWidth, imgHeight);
    pdf.save('constancia_feliuas_2024.pdf'); // Descargar el PDF con un nombre específico
  };

  return (
    <div className="certificateFormPage position-relative">
      <Helmet>
        <meta name="description" content="Constancias del evento FeliUAS 2024" />
        <title>Constancias FeliUAS 2024</title>
        <style>
          {`
            html,
            body {
              background-color: #c2dde6;
            }
          `}
        </style>  
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
        theme="light"
      />
      { isLoading && (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 9999, backgroundColor: 'rgba(239, 239, 240,0.5)' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      {
        certificate ? 
          <>
            <div className="container certificate-container">
              <img className="logos-uas" src={LogosUas} alt="" width="auto" height="auto" />
              <div className="pdfDiv" style={{ width: '100%', height: 'calc(100vh - 200px)', overflow: 'auto' }}>
                <img id="certificate" className='certificateImg' src={certificate} alt="Constancia FELIUAS 2024" style={{ width: '100%', height: 'auto' }} />
              </div>
              <Button className="btn" onClick={handleDownload}>Descargar</Button>
            </div>
          </>
        : (
          <main className="form-container w-100 m-auto">
            <img className="logos-uas" src={LogosUas} alt="" width="auto" height="auto" />
            <Form validated={validated} onSubmit={handleSubmit} className="form-signin">
              <img className="mb-4 logo-feria" src={LogoFeria} alt="" width="auto" height="auto" />
              <span className="h4 instructions">Introduce el nombre completo y correo que usaste en el registro para obtener tu constancia del evento.</span>
              <Form.Group controlId="name" className="inputName">
                <div>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Nombre completo"
                    onChange={handleChange}
                    value={formData.name}
                    required
                    pattern=".{4,}" 
                    title="Por favor, introduce tu nombre completo"
                    isInvalid={
                        validated &&
                        !/^.{4,}$/.test(formData.name)
                    }
                  />
                  <Form.Control.Feedback type='invalid'>Coloca tu nombre completo</Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group controlId="email" className="inputEmail">
                <div>
                    <Form.Control
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="correo@gmail.com"
                      onChange={handleChange}
                      required
                      value={formData.email}
                    />
                    <Form.Control.Feedback type='invalid'>Email no valido</Form.Control.Feedback>
                </div>
              </Form.Group>
              <Button className="btn" type="submit">CONTINUAR</Button>
            </Form>
          </main>
        )}
    </div>
  );
};

export default CertificateFeliuasFormPage;
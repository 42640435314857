// Formulario.js
import React, { useEffect, useState } from 'react';
import LogosUas from '../assets/logos_uas_white.png';
import axios from 'axios';
import { Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CertificateValidatePage = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [data, setData] = useState({
    name: "",
    event: "",
    date: "",
  });

  
  useEffect(() => {
    //axios.get('http://localhost:4000/certificate/validate/'+id)
    axios.get('https://api.constancias-urs.uas.edu.mx/certificate/validate/' + id)
      .then(response => {
        if(response && response.data && response.data.success) {
          const url = 'https://constancias-urs.uas.edu.mx/certificates/' + response.data.filename;
          setIsValid(true);
          setData(response.data);
        } else {
          if(response && response.data && response.data.error_description) {
            toast(response.data.error_description, {
              type: 'error'
            });
          }
          setIsValid(false);
        }
        setLoading(false);
      })
      .catch( error => {
        toast("Ocurrio un error, intenta de nuevo.", {
          type: 'error'
        });
        setLoading(false);
      });
  }, [id]);

  return (
    <div className="certificateValidatePage position-relative">
      <Helmet>
        <meta name="description" content="Validador de constancias URS 2024" />
        <title>Validador de constancias URS 2024</title>
        <style>
          {`
            html,
            body {
              background-color: #F0EBE0;
            }
          `}
        </style>  
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
        theme="light"
      />
      { isLoading && (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 9999, backgroundColor: 'rgba(239, 239, 240,0.5)' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
        <div className="container certificate-validate-container">
          <img className="logos-uas" src={LogosUas} alt="" width="auto" height="auto" />
          { isValid && (
            <div className="certificate-validate-data">
                <h2>DATOS DEL RECONOCIMIENTO</h2>
                <div className="data-field">
                  <span className="tag"><span className="icon icon-user"></span><span className="tag-value">NOMBRE</span></span>
                  <span className="value">{data.name}</span>
                </div>
                <div className="data-field">
                  <span className="tag"><span className="icon icon-conference"></span><span className="tag-value">EVENTO</span></span>
                  <span className="value">{data.event}</span>
                </div>
                <div className="data-field">
                  <span className="tag"><span className="icon icon-date"></span><span className="tag-value">FECHA</span></span>
                  <span className="value">{data.date}</span>
                </div>
            </div>
          )}
        </div>
    </div>
  );
};

export default CertificateValidatePage;
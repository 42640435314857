// Formulario.js
import React, { useEffect, useState } from 'react';
import LogosUas from '../assets/logos_uas.png';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <div>
        <Helmet>
        <meta name="description" content="Constancias UAS URS" />
        <meta property="og:title" content="Constancias UAS URS" />
        <meta property="og:description" content="Constancias de los eventos de la Universidad Autonoma de Sinaloa" />
        <meta property="og:image" content="https://constancias-urs.uas.edu.mx/images/logo.png" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Constancias UAS URS" />
        <title>Constancias UAS URS</title>
        <style>
          {`
            body, html {
              height: 100%;
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .logo {
              max-width: 500px;
              height: auto;
            }
          `}
        </style>  
      </Helmet>
      <img src="/images/logo.png" alt="Logo" class="logo" />
    </div>
  );
};

export default HomePage;